import { createSlice } from "@reduxjs/toolkit";
import {
    getProductDetails,
    getProducts,
    getRelatedProducts,
    getPackages,
    getMultipleCategoryProducts,
    getOrderCompletedImages,
    getGuestLocationId
} from "../thunks/guestActions";
import PRODUCT_CATEGORIES from "../../constants/ProductCategories";


const termSlice = createSlice({
    name: "term",
    initialState: {
        term: "Food",
    },
    reducers: {
        updateTerm: (state, action) => {
            state.term = action?.payload || "Food";
        }
    }
});


const productsSlice = createSlice({
    name: "products",
    initialState: {
        loading: false,
        products: null,
        random: null,
        error: "",
        start: 0,
        limit: 10,
        total: 0,
        activePage: 1,
    },
    reducers: {
        setActivePage: (state, action) => {
            state.activePage = action.payload.page;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProducts.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload.products;
                state.random = action.payload;
                state.error = ""
                state.total = action.payload.total;
            })
            .addCase(getProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const multipleCategoryProductsSlice = createSlice({
    name: "multipleCategoryProducts",
    initialState: () => {
        return PRODUCT_CATEGORIES.reduce((companiesAcc, company) => {
            const companyCategories = company.categories;
            const totalContainers = Math.ceil(companyCategories.length / 3);
            
            const containersMap = [...Array(totalContainers).keys()].map(i => i + 1).reduce((acc, containerNumber) => {
                return {
                    ...acc,
                    [containerNumber]: false
                };
            }, {});
            const productCategoryMap = companyCategories.reduce((acc, productCategory) => {
                const productProperties = {
                    loading: false,
                    products: [],
                    error: '',
                    total: 0,
                    load: 0,
                    endPage: 0,
                    actualPage: 1,
                    firstLoadInMobileDone: false
                };
                return {
                    ...acc,
                    [productCategory]: productProperties
                };
            }, {});
            return {
                ...companiesAcc,
                [company.companyName]: {
                    ...productCategoryMap,
                    firstProductsLoaded: false,
                    error: '',
                    containersMap,
                    containerShown: []
                }
            };
        }, {});
    },
    reducers: {
        setActualPage: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            if (company) {
                const companyData = JSON.parse(company);
                const newValue = action.payload.action === 'next' ? 1 : -1;
                state[companyData.name][action.payload.category].actualPage += newValue;
            }
        },        
        setFirstLoadInMobileDone: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            if (company) {
                const companyData = JSON.parse(company);
                state[companyData.name][action.payload.category].firstLoadInMobileDone = true;
            }
        }, 
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMultipleCategoryProducts.pending, (state, action) => {
                const company = localStorage.getItem('activeCompany');
                const companyData = JSON.parse(company);
                action.meta.arg.customterms.forEach((category) => {
                    state[companyData.name][category].loading = true;
                    state[companyData.name][category].error = '';
                });
            })
            .addCase(getMultipleCategoryProducts.fulfilled, (state, action) => {
                const company = localStorage.getItem('activeCompany');
                const companyData = JSON.parse(company);
                action.meta.arg.customterms.forEach((category, index) => {
                    state[companyData.name][category].loading = false;
                    state[companyData.name][category].load = state[companyData.name][category].load + 1;
                    state[companyData.name][category].products = !action.payload.totals ? [...state[companyData.name][category].products, ...action.payload.products] : action.payload.products[index];
                    state[companyData.name][category].total = !action.payload.totals ? state[companyData.name][category].total : action.payload.totals[index];
                    if (action.payload.totals) {
                        state[companyData.name][category].endPage = state[companyData.name][category].total % 4 === 0
                            ? state[companyData.name][category].total / 4
                            : Math.ceil(state[companyData.name][category].total / 4);
                    }
                });
                state.firstProductsLoaded = true;
            })
            .addCase(getMultipleCategoryProducts.rejected, (state, action) => {
                const company = localStorage.getItem('activeCompany');
                const companyData = JSON.parse(company);
                action.meta.arg.customterms.forEach((category) => {
                    state[companyData.name][category].loading = false;
                    state[companyData.name][category].error = action.payload?.error;
                });
                state.error = action.payload?.error;
            });
    },
});


const productDetailsSlice = createSlice({
    name: "productDetails",
    initialState: {
        loading: false,
        details: {},
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductDetails.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getProductDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.details = action.payload;
            })
            .addCase(getProductDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const relatedProductsSlice = createSlice({
    name: "relatedProducts",
    initialState: {
        loading: false,
        products: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRelatedProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRelatedProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(getRelatedProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const guestLocationIdSlice = createSlice({
    name: "guestLocationId",
    initialState: {
        loading: 'initial',
        property: null,
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGuestLocationId.pending, (state) => {
                state.loading = 'loading';
            })
            .addCase(getGuestLocationId.fulfilled, (state, action) => {
                state.loading = 'ready';
                state.property = action.payload;
            })
            .addCase(getGuestLocationId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        message: "",
        cartItems: {
            Ralphs: [],
            WholeFoods: [],
            Sprouts: []
        },
        error: "",
    },
    reducers: {

        restoreCart: (state, action) => {
            state.cartItems = action.payload;
            localStorage.setItem("cartItems", JSON.stringify(action.payload));
          },
        clearCart: (state) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData.name] = [];
            state.message = "";
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems[companyData.name]));
        },
        addToCart: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);

            console.log(state.cartItems[companyData?.name]);
            const itemExist = state.cartItems[companyData?.name] && state.cartItems[companyData?.name].find(
                (existingProduct) => existingProduct.productId === action.payload.productId
            );

            if (!itemExist) {
                const newItem = {
                    ...action.payload,
                    substituteOrRefund: "substitute" // default value
                };

                const items = state.cartItems[companyData?.name]
                    ? [...state.cartItems[companyData?.name], newItem]
                    : [newItem]; // Create a new array with the new item

                state.cartItems = {
                    ...state.cartItems,
                    [companyData?.name]: items
                };
                state.message = "Product Added Successfully";
            } else {
                console.log('old item entry => ', state.cartItems[companyData.name])
                state.cartItems[companyData.name] = state.cartItems[companyData.name].map((item) => {
                    if (item.productId === action.payload.productId) {
                        return {
                            ...item,
                            quantity: item.quantity + 1
                        };
                    }
                    return item;
                });
                state.message = "Product Quantity Increased";
            }
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        removeFromCart: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData?.name] = state.cartItems[companyData?.name].filter(
                (item) => item.productId !== action.payload.productId
            );
            state.message = "Product Removed From Cart"
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        },
        increaseQuantity: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData.name] = state.cartItems[companyData.name].map((item) => {
                if (item.productId === action.payload) {
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems[companyData.name]));
        },
        decreaseQuantity: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData.name] = state.cartItems[companyData.name].map((item) => {
                if (item.productId === action.payload) {
                    return {
                        ...item,
                        quantity: item.quantity - 1,
                    };
                }
                return item;
            });
            localStorage.setItem("cartItems", JSON.stringify(state.cartItems[companyData.name]));
        },
        updateSubstituteOrRefund: (state, action) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData.name] = state.cartItems[companyData.name].map((item) => {
                if (item.productId === action.payload.productId) {
                    return {
                        ...item,
                        substituteOrRefund: action.payload.substituteOrRefund,
                    };
                }
                return item;
            });
        },
        refreshCartMsg: (state) => {
            state.message = "";
            state.error = "";
        },
        removeCartData: (state) => {
            const company = localStorage.getItem('activeCompany');
            const companyData = JSON.parse(company);
            state.cartItems[companyData.name] = []
        }
    },
});

const packagesSlice = createSlice({
    name: "packages",
    initialState: {
        loading: false,
        packages: [],
        error: "",
        totalPrice: null,
    },
    reducers: {
        setTotalPrice: (state, action) => {
            state.totalPrice = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackages.pending, (state) => {
                state.loading = true;
                state.error = ""
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.packages = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error
            });
    },
});

const categoriesBarSlice = createSlice({
    name: 'categoryBar',
    initialState: false,
    reducers: {
        setBarOn: state => state = true,
        setBarOff: state => state = false,
    }
})

const guestBlurSlice = createSlice({
    name: 'guestBlur',
    initialState: false,
    reducers: {
        setBlurOn: state => state = true,
        setBlurOff: state => state = false
    }
})

const OrderCompletedImagesSlices = createSlice({
    name: "OrderCompletedImages",
    initialState: {
        loading: false,
        imagesList: [],
        error: "",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getOrderCompletedImages.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrderCompletedImages.fulfilled, (state, action) => {
                state.loading = false;
                state.imagesList = action.payload;
            })
            .addCase(getOrderCompletedImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error;
            });
    },
});

const dateTimeSlice = createSlice({
    name: "DateTime",
    initialState: {
        arrivalDate: new Date(),
        arrivalTime: new Date(),
        statusDate: 'ready',
        statusTime: 'ready'
    },
    reducers: {
        setArrivalDate: (state, action) => {
            try {
                state.arrivalDate = action.payload;
                state.statusDate = 'success';
            } catch {
                state.statusDate = 'fail';
            }

        },
        setArrivalTime: (state, action) => {
            try {
                state.arrivalTime = action.payload;
                state.statusTime = 'success';
            } catch {
                state.statusTime = 'fail';
            }
        }
    }
});

const activeCompanySlice = createSlice({
    name: 'activeCompany',
    initialState: {
        activeCompany: {}
    },
    reducers: {
        setActiveCompany: (state, action) => {
            state.activeCompany = action.payload;
        }
    }
});

export const { setActiveCompany } = activeCompanySlice.actions;
export const { removeCartData, addToCart, removeFromCart, increaseQuantity, decreaseQuantity, refreshCartMsg, clearCart, restoreCart } = cartSlice.actions;
export const { updateTerm } = termSlice.actions
export const { setBarOn, setBarOff } = categoriesBarSlice.actions
export const { setBlurOn, setBlurOff } = guestBlurSlice.actions
export const { setActivePage } = productsSlice.actions
export const { setArrivalDate, setArrivalTime } = dateTimeSlice.actions;
export const { setActualPage, setFirstLoadInMobileDone } = multipleCategoryProductsSlice.actions;

export { activeCompanySlice, productsSlice, productDetailsSlice, relatedProductsSlice, cartSlice, packagesSlice, categoriesBarSlice, guestBlurSlice, termSlice, multipleCategoryProductsSlice, OrderCompletedImagesSlices, dateTimeSlice, guestLocationIdSlice };
export const { updateSubstituteOrRefund } = cartSlice.actions;
