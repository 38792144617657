import React, { useState, useEffect } from 'react';
import logo from "../icons/bellhoptLogoBlack.svg";
import cart from "../icons/cart.svg";
import burgerMenuWhite from "../icons/mobile-burger-black.svg";
import searchIcon from "../icons/search-icon-black.svg";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import leftArrow from '../icons/leftArrow.svg'
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../store/thunks/guestActions";
import axios from 'axios'
import SaveCartButton from './SaveCartButton';

import {
  setActivePage,
  setBarOff,
  setBarOn,
  setBlurOff,
  setBlurOn,
  updateTerm,
} from "../store/slices/guestSlices";

function GuestNav(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [search, setSearch] = useState("");
  const { guestBlur } = useSelector((state) => state);
  const { cartItems } = useSelector((state) => state.cart);

  let companyData = useSelector(
      (state) => state.activeCompany.activeCompany
  );

  if (companyData && !companyData?.name) {
    const company = localStorage.getItem("activeCompany")
    companyData = JSON.parse(company);
  }

  const cartItemCount = companyData && cartItems[companyData?.name] ? cartItems[companyData?.name].length : 0;

  const handleSearchBtn = (e) => {
    console.log("nav");
    e.preventDefault();
    
    const searchTerm = encodeURIComponent(search);


    dispatch(updateTerm(search));
    dispatch(getProducts({ term: searchTerm }));

    dispatch(setActivePage({
      page:1
    }))

    if (location.pathname !== "homeproducts") {
      navigate("/homeproducts");
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      document.querySelector("#searchBtn").click();
      props.func(10);
    }
  };

  const handleHamBurgerClick = () => {
    dispatch(setBarOn());
    dispatch(setBlurOn());
  }
  const [additionalLogoUrl, setAdditionalLogoUrl] = useState(null);
  const propertyId = JSON.parse(localStorage.getItem("property"))?._id;

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (propertyId) {
        try {
          // Make an API call to get the logo URL for the given propertyId
          const response = await axios.get(`https://api.bellhopt.com/api/v1/logo-by-property/${propertyId}`);
          if (response.data.logoUrl) {
            // Set the logo URL only if it's returned by the backend
            setAdditionalLogoUrl(response.data.logoUrl);
          }
        } catch (error) {
          console.error('Error fetching additional logo:', error);
          // Optionally handle the error, e.g., by resetting the logoUrl state
          setAdditionalLogoUrl(null);
        }
      }
    };

    fetchLogoUrl();
  }, [propertyId]);


  return (
    <>
      {guestBlur && (
        <div
          onClick={() => {
            dispatch(setBlurOff());
            dispatch(setBarOff());
          }}
          className={`fixed opacity-40 top-0 left-0 h-screen w-full bg-black z-10 min-h-[650px]`}
        ></div>
      )}
      {/* MOBILE */}
      <div className="header w-full hidden tablet:block">
        <div className="w-full px-2 pb-4 m-auto flex flex-col gap-3 mb-3 h-fit mobile:fixed mobile:z-10 mobile:bg-[#FFFFFF] shadow-sm mobile:pb-[10px] mobile:mb-[10px]">
          <div className="flex justify-between items-center">
            <div className="brand">
            <NavLink to="/guest" className="flex items-center">
              <img className="h-[60px] mr-4" src={logo} alt="Current Logo" style={{ maxWidth: '80px' }} />
              {/* Display additional logo if available */}
              {additionalLogoUrl && (
                 <img className="h-[60px]" src={additionalLogoUrl} alt="Additional Logo" style={{ height: '60px', width: 'auto', maxWidth: '180px' }} />
              )}
            </NavLink>


            </div>
            <SaveCartButton />
            {/* <NavLink
              to={"/cart"}
              className=" flex justify-between items-center bg-[red] text-white font-bold p-[20px] rounded-[42px] w-[80px] h-[30px] cursor-pointer"
            >
              <div>
                <img src={cart} className="w-[25px]" />
              </div>
              <div>
                <h1>{cartItemCount}</h1>
              </div>
            </NavLink> */}
          </div>

          <div className="flex justify-between gap-3 mobile:gap-7">

            <div className="mobile:fixed mobile:z-10 mobile:rounded-full">
            <img
              src={burgerMenuWhite}
              alt="responsive"
              className={`laptop:hidden mobile:p-3  cursor-pointer ${!(location.pathname.startsWith('/guest')) && location.pathname !== '/homeproducts' && 'hidden'}`}

              onClick={() => {
                handleHamBurgerClick()
              }}
            />
            </div>


            {props.back ? (
              <div className="w-[10%] flex items-center"><img src={leftArrow} /></div>
            
            ): (<div className="w-[10%]"></div>)} 

            
            
            <div className="relative w-[85%] ">
              <button
                id="searchBtn"
                onClick={handleSearchBtn}
                className="w-[20px] absolute top-0 bottom-0 right-5"
              >
                <img className="w-full" src={searchIcon} alt="" />
              </button>
              <input
                type="text"
                placeholder="Search products here"
                className="w-full h-[40px] bg-[#FFFFFF] rounded-full px-5 outline-none border border-lightborder text-[#222222]"
                onKeyDown={handleEnterKey}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="header w-full h-[70px] bg-[#FFFFFF] tablet:hidden fixed z-[100] shadow-sm">
        <div className="w-[90%] m-auto flex justify-between items-center h-full tablet:w-[90%]">
          <div className="brand">
          <NavLink to="/guest" className="flex items-center">
            <img className="h-[60px] mr-4" src={logo} alt="Current Logo" style={{ maxWidth: '80px' }} />
            {/* Display additional logo if available */}
            {additionalLogoUrl && (
               <img className="h-[60px]" src={additionalLogoUrl} alt="Additional Logo" style={{ height: '60px', width: 'auto' }} />
            )}
          </NavLink>


          </div>

          {/* <div> */}
          <div className="flex gap-2 items-center">
            <SaveCartButton />
            <div className="relative text-[#FFFFFF]">
              <button
                id="searchBtn"
                onClick={handleSearchBtn}
                className="w-[20px] absolute top-0 bottom-0 left-3"
              >
                <img className="w-full" src={searchIcon} alt="" />
              </button>
              <input
                onKeyDown={handleEnterKey}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search products here"
                className="w-[400px] tablet:max-w-[260px] h-[30px] bg-[#FFFFFF] rounded-full p-[20px] pl-[40px] outline-none border border-lightborder text-[#222222]"
              />
            </div>
            <NavLink
              to={"/cart"}
              className=" flex justify-between items-center bg-[red] text-white font-bold p-[20px] rounded-[42px] w-[80px] h-[30px] cursor-pointer"
            >
              <div>
                <img src={cart} className="w-[25px]" />
              </div>
              <div>
                <h1>{cartItemCount}</h1>
              </div>
            </NavLink>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default GuestNav;
