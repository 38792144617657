const PRODUCT_CATEGORIES = 
[
    {
        companyName: 'Ralphs',
        categories: [
            "Fresh Fruit",
            "Fresh Vegetables",
            "Milk",
          
         
            "Water",
            "Sparkling Water",
            "Lactose Free Milk",   
            "Eggs",

            "Bacon",
            "Cheese",
            "Fresh Smoothies",
            "Cereal",

            "Granola",
            "Chobani Yogurt",
            "Fresh Berries",

            "Organic Fruit",
            "Organic Eggs",
            "Organic Milk",


            "Soft Drinks",
            "Oatmeal",
            "Ritz",
            "Goldfish",

            "Bread",
            "Peanut Butter",
            "Dave's Killer Bread",

            "Boar’s Head Deli Meat",
            "Popcorn",
            "Butter",

            "Organic Snacks",
            "Snacks",
            "Kids Snacks",

            "Doritos",
            "Oreo",
            "Lay's Potato Chips",

            "Haagen Dazs Ice Cream",
            "Ben and Jerry's Ice Cream",
            "Ice Cream Bars",

            "Cooking Oil",
            "Condiments",
            "Spices",

            "Pancake",
            "Syrup",
            "Breakfast Sausage",

            "Charcoal",
            "Paper Plates",
            "Plastic Cutlery",


            "Meats",
            "Beef",
            "Chicken",

            "Orange Juice",
            "Green Tea Bags",
            "Starbucks",


            "Coffee Creamer",
            "Coffee Beans",
            "Coffee K-Cups",



            "Liquor",
            "Beer",
            "Red Wine",

            "Hard Seltzer",
            "Non-Alcoholic Beer",
            "IPA Beer",


            "Pretzels",
            "Sabra Hummus",
            "Pita Chips",


            "Tortilla Chips",
            "Salsa",
            "Guacamole",


            "Trail Mix",
            "Dried Fruit",
            "Nuts",


            "Non-Stick Cooking Spray",
            "Aluminum Foil",
            "Cookie Dough",


            "Krispy Kreme Doughnuts",
            "Donuts and Pastries",
            "Cinnamon Rolls",


            "Organic Vegetables",
            "Fresh Herbs",
            "Organic Salad",


            "Frozen Breakfast",
            "Frozen Snacks",
            "Frozen Pizza",


            "Sports Drink",
            "Protein Bar",
            "Energy Drink Variety Pack",


            "Baby Diapers",
            "Baby Foods",
            "Baby Wipes",

            "Toothbrush",
            "Toothpaste",
            "Shaving Cream",
            "Emergen-C",
            "Pain Relief",
            "Sanitizer Wipes",

            "Sunscreen",
            "Insect Repellent",
            "Disposable Razors",

            "Flower Bouquet",
            "Party Trays",
            "Napkins",


            "Puzzle",
            "Lego",
            "Stuffed Animal",


            "Nerf",
            "Barbie",
            "Hot Wheels",

            "Pet Food",
            "Dog Toys",
            "Dog Treats",
        ]
    }, 
    {
        companyName: 'Sprouts',
        categories: [
            "Produce", "Fruits", "Vegetables", "Toppings, Refrigerated Dressings & Dips", "Floral",
            "Deli", "Deli Meats & Cheese", "Specialty & Domestic Cheeses", "Heat & Eat", "Ready To Eat", "Snacks",
            "Beverages",
            "Bakery", "Bread", "Tortillas & Flat Breads", "Baked Goods & Desserts",
            "Bulk", "Fruit & Nuts", "Bulk Baking & Spices", "Coffee & Tea", "Snacks & Sweets", "Beans, Peas & Grains", "Granola & Cereal", "Nut Butter & Honey",
            "Dairy", "Milk", "Butter & Spreads", "Eggs", "Yogurts", "Cultured", "Plant-Based Alternatives", "Refrigerated Baking & Raw Foods", "Refrigerated Beverages",
            "Meat & Seafood", "All Natural Beef", "All Natural Poultry", "All Natural Pork", "Grass Fed Lamb", "Sausage", "Further Prepared Meats", "Prepackaged/Smoked", "Seafood", "Frozen", "Bison",
            "Grocery Aisles", "Snacks", "Nutrition Bars", "Beverages", "Breakfast", "Baking & Spices", "Soups, Broths & Sauces", "Nut Butters & Fruit Spreads", "Oils & Dressings", "Baby Care", "Honey & Syrups", "Canned & Dry Goods", "Condiments", "Meals", "International Foods", "Household", "Pet Care",
            "Frozen", "Entrees", "Plant-Based", "Snacks & Pizzas", "Appetizers & Burritos", "Frozen Fruits", "Frozen Vegetables", "Frozen Potatoes", "Breakfast Foods", "Desserts & Tortillas", "Ice Cream & Novelties", "Ice",
            "Vitamins & Body Care", "Supplements", "Nutritional Oils", "Wellness", "Body Care",
            "Beer & Wine", "Red Wine", "White Wine", "Rose & Blush", "Champagne & Sparkling", "Beer", "Non-Alcoholic", "Sweet Wines", "Specialty"
        ]
    },
    {
        companyName: 'WholeFoods',
        categories: [
            "Pet",
            "Meat",
            "Beauty",
            "Produce",
            "Seafood",
            "Desserts",
            "Beverages",
            "Body Care",
            "Household",
            "Lifestyle",
            "Baby Child",
            "Dairy Eggs",
            "Supplements",
            "Frozen Foods",
            "Prepared Foods",
            "Pantry Essentials",
            "Wine Beer Spirits",
            "Breads Rolls Bakery",
            "Snacks Chips Salsas Dips"
        ]

    },
    {
        companyName: 'Vons',
        categories: [
            'Fresh Fruits',
            'Fresh Vegetables',
            'Packaged Produce',
            'Cereal',
            'Meat & Seafood',
            'Bakery',
            'Bread & Bakery',
            'Beverages',
            'Coffee',
            'Tea',
            'Breakfast Bars',
            'Soft Drinks',
            'Snacks & Candy',
            'Toaster Pastries',
            'Syrup',
            'Chicken & Turkey',
            'Beef',
            'Fish & Shellfish',
            'Nuts & Dried Fruits',
            'Dairy',
            'Milk & Cream',
            'Cheese',
            'Butter & Sour Cream',
            'Deli',
            'Deli Specialty Foods',
            'Deli Soups',
            'Deli Sides & Meals',
            'Bulk Foods',
            'Sushi',
            'Cookies & Bakery Snacks',
            'Cakes & Cupcakes',
            'Frozen',
            'Frozen Vegetables',
            'Frozen Meals & Sides',
            'Pasta Sauce',
        ]
    }
]
 
 
 export default PRODUCT_CATEGORIES;
