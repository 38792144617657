import * as Yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Extend the checkoutSchema to include userInputAddress validations
const checkoutSchema = Yup.object({
  email: Yup.string()
  .trim()
  .matches(
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    "Please enter a valid email address (e.g. alex@example.com)"
  )
  .nullable(),
  guestPhone: Yup.string()
    .required("Phone Number is Required")
    .matches(phoneRegExp, "Enter a valid phone number"),
  guestEmail: Yup.string()
    .required("Email is Required in Contact Information")
    .matches(emailRegex, "Please enter a valid email address (e.g. alex@example.com)"),
  userInputAddress: Yup.object().shape({
    propertyManager: Yup.string(),
    line1: Yup.string(),
    line2: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipCode: Yup.string(),
    entryInstructions: Yup.string()
  })
});
const contactSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is Required in Contact information").email("Please enter a valid email").lowercase(),
    phone: Yup.string().required("Phone Number is Required").matches(phoneRegExp, "Enter a valid phone number"),
    message: Yup.string().required("Message cant be Empty")
});

export { checkoutSchema, contactSchema };
