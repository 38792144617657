import React from 'react'
import { useState } from 'react';
import ReactModal from "react-modal";
import { contactSchema } from '../utils/validationSchema';
import { useFormik } from 'formik';
import { Alert, CircularProgress } from '@mui/material';
import request from '../utils/request';
import { useEffect } from 'react';

const ContactUs = ({ showModal, handleCloseModal }) => {

    const [error, setError] = useState();
    const [errorFormik, setErrorFormik] = useState();
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);

    const { values, errors, touched, handleBlur, handleChange } = useFormik(
        {
            initialValues: {
                name: "",
                email: "",
                phone: "",
                message: ""
            },
            validationSchema: contactSchema,
        }
    )

    const handleSubmit = async () => {
        console.log(values)

        if (!values.email || !values.phone || !values.message) {
            setErrorFormik(errors[Object.keys(errors)[0]] ? errors[Object.keys(errors)[0]] : 'Please Fill in all the Fields');

            return;
        }

        if (Object.keys(errors).length > 0) {
            setErrorFormik(errors[Object.keys(errors)[0]]);

            return;
        }

        if (Object.keys(errors).length === 0) {

            setLoading(true)
            setError(null)

            try {
                const res = await request.post(`/contact`, values);
                console.log(res)
                if (res.data.success) {
                    setError(null)
                    setErrorFormik(null)
                    setLoading(false)
                    setMessage(res.data.message)
                    handleCloseModal()
                }
            }
            catch (err) {
                setError(err.response.data.error)
                setLoading(false)
            }
        }
    }

    useEffect(() => {

        setTimeout(() => {
            error && setError(null)
            errorFormik && setErrorFormik(null)
            message && setMessage(null)
        }, 3000)

    }, [error, errorFormik, message])

    return (
        
        <div className='h-fit'>
            {message && (
                <Alert
                    severity="success"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 10,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {message}
                </Alert>
            )}
            {error && (
                <Alert
                    severity="warning"
                    sx={{
                        position: "fixed",
                        width: "90%",
                        margin: "auto",
                        top: 10,
                        left: 0,
                        right: 0,
                        zIndex: 10,
                    }}
                >
                    {error}
                </Alert>
            )}

            <ReactModal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                className="Modal contactUs"
                ariaHideApp={false}
                appElement={document.getElementById("root")}
                overlayClassName="Overlay"
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: 999999
                    },
                    content: {
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      backgroundColor: '#fff',
                      border: 'none',
                      outline: 'none',
                      borderRadius: '8px',
                      padding: '20px',
                      maxWidth: '90vw',
                      maxHeight: '90vh',
                      zIndex: 1000000
                    }
                  }}
            >
                  {/* ✕ Close Button */}
  <button
    onClick={handleCloseModal}
    className="absolute top-3 right-4 text-gray-500 text-xl font-bold z-50"
  >
    ✕
  </button>

                <div className="flex flex-col p-5">


                    {errorFormik && <Alert severity="warning">
                        {errorFormik}
                    </Alert>}

                    <h1 className='text-lg m-aut font-bold'>Contact Us</h1>

                    <div className="w-full flex flex-col gap-1">
                        <div className="flex gap-1">
                            <label htmlFor="guestPhone" className="bg-white text-light">
                                Name
                            </label>
                            {errors.name && touched.name ? (
                                <p className="text-[red] inline-block pl-1">*</p>
                            ) : null}
                        </div>
                        <input
                            className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                        />
                    </div>

                    <div className="w-full mt-2">
                        <div className="flex tablet:flex-col gap-5 mt-2">

                            <div className="w-full flex flex-col gap-1">

                                <div className="flex gap-1">
                                    <label htmlFor="guestPhone" className="bg-white text-light">
                                        Phone Number
                                    </label>
                                    {errors.phone && touched.phone ? (
                                        <p className="text-[red] inline-block pl-1">*</p>
                                    ) : null}
                                </div>
                                <input
                                    className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                                    type="text"
                                    name="phone"
                                    placeholder="+1 949 786 9999"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                />
                            </div>

                            <div className="w-full flex flex-col gap-1">

                                <div className="flex gap-1">
                                    <label htmlFor="guestEmail" className="bg-white text-light">
                                        Email
                                    </label>
                                    {errors.email && touched.email ? (
                                        <p className="text-[red] inline-block pl-1">*</p>
                                    ) : null}
                                </div>
                                <input
                                    className="outline-none border border-lightborder w-full px-3 py-2 overflow-auto rounded-lg"
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* message box */}
                    <div className="w-full flex flex-col gap-2 mt-4">
                        <div className="flex gap-1">
                            <label htmlFor="message" className="bg-white text-light">
                                Message
                            </label>
                            {errors.message && touched.message ? (
                                <p className="text-[red] inline-block pl-1">*</p>
                            ) : null}
                        </div>
                        <div className="w-full flex flex-col gap-1">
                            <textarea
                                className="outline-none border border-lightborder w-full px-3 py-2 rounded-lg"
                                type="text"
                                name="message"
                                rows={5}
                                placeholder="Type your message here..."
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                            />
                        </div>

                        <button
                            className="w-full bg-primary text-lightborder font-semibold text-[20px] py-2 rounded-lg"
                            type="submit"
                            onClick={handleSubmit}
                        >
                            {loading ? <CircularProgress color="inherit" className="mr-2" size={'20px'} /> : "Submit"}
                        </button>
                    </div>

                </div>
            </ReactModal>
        </div>
    )
}

export default ContactUs