import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import request from '../utils/request';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

const SaveCartButton = () => {
  const rawCartItems = useSelector((state) => state.cart.cartItems || {});
  const cartItems = Object.values(rawCartItems).flat();
  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      inputMode="none"
      onClick={onClick}
      ref={ref}
      value={value}
      readOnly
      placeholder="Select Date"
      className="w-full border border-lightborder rounded px-3 py-[10px] bg-white text-[13px] text-gray-800 text-sm focus:outline-none"
    />
  ));
  
  const CustomTimeInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      inputMode="none"
      onClick={onClick}
      ref={ref}
      value={value}
      readOnly
      placeholder="Select Time"
      className="w-full border border-lightborder rounded px-3 py-[10px] bg-white text-[13px] text-gray-800 text-sm focus:outline-none"
    />
  ));
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    arrivalDate: null,
    arrivalTime: null,
    consent: false
  });
  const [autosaving, setAutosaving] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  let savedCartCode = null;
  try {
    savedCartCode = localStorage.getItem('savedCartCode');
  } catch (err) {
    console.warn('Could not access localStorage:', err);
  }
  
  const isCartCodePresent = Boolean(savedCartCode);
  
  const previousCartRef = useRef(null);

  const debouncedUpdate = useRef(
    debounce((cartCode, payload) => {
      setAutosaving(true);
      request.put(`/carts/${cartCode}`, payload)
        .then(res => {
          if (res.data.success) {
            console.log('✅ Cart auto-updated');
          }
        })
        .catch(err => {
          console.error('❌ Failed to auto-update cart:', err);
        })
        .finally(() => setAutosaving(false));
    }, 1000)
  ).current;

  useEffect(() => {
    const property = JSON.parse(localStorage.getItem('property'));
  
    if (!savedCartCode || !property?.uniqueId || !cartItems.length) return;
  
    if (!previousCartRef.current || !isEqual(previousCartRef.current, cartItems)) {
      previousCartRef.current = cartItems;
  
      const payload = {
        cartItems,
        propertyProps: property,
      };
  
      debouncedUpdate(savedCartCode, payload);
    }
  }, [cartItems, savedCartCode]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleConsent = (e) => {
    setForm((prev) => ({ ...prev, consent: e.target.checked }));
  };

  const handleSubmit = async () => {
    const property = JSON.parse(localStorage.getItem('property'));

    if (!property?.uniqueId) {
      alert('Missing property info. Please refresh and try again.');
      return;
    }

    if (!cartItems.length) {
      alert('Your cart is empty.');
      return;
    }

    const { firstName, lastName, email, arrivalDate, arrivalTime, consent } = form;
    if (!firstName || !lastName || !email || !arrivalDate || !arrivalTime || !consent) {
      alert('Please complete all fields and agree to receive email reminders.');
      return;
    }

      
    const combinedDateTime = new Date(
      arrivalDate.getFullYear(),
      arrivalDate.getMonth(),
      arrivalDate.getDate(),
      arrivalTime.getHours(),
      arrivalTime.getMinutes()
    );
    const localISOString = new Date(combinedDateTime.getTime() - combinedDateTime.getTimezoneOffset() * 60000).toISOString();

    const payload = {
      ...form,
      arrivalDate: localISOString,
      arrivalTime: localISOString,
      cartItems,
      propertyProps: property,
      uniqueId: property?.uniqueId,
    };

    try {
      const response = await request.post('/carts', payload);
      if (response.data.success) {
        localStorage.setItem('savedCartCode', response.data.cartCode);
        setShowMessage(true);
        setOpen(false);
      }
    } catch (err) {
      console.error(err);
      alert('Error saving cart. Please try again.');
    }
  };

  return (
    <>
      {showMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#EF4949',
            color: 'white',
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
            padding: '16px 24px',
            zIndex: 1300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minWidth: '300px',
            maxWidth: '90%',
            fontFamily: 'Segoe UI',
          }}
        >
          <span>Your saved cart has been emailed to you!</span>
          <Button
            onClick={() => setShowMessage(false)}
            sx={{
              color: 'white',
              fontSize: '18px',
              minWidth: 0,
              padding: 0,
              marginLeft: 2,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            ×
          </Button>
        </Box>
      )}

{cartItems.length > 0 && (
  <Button
    variant="outlined"
    onClick={() => setOpen(true)}
    disabled={isCartCodePresent}
    sx={{
      backgroundColor: isCartCodePresent ? '#000' : '#EF4949',
      color: '#fff',
      borderColor: isCartCodePresent ? '#000' : 'red',
      borderRadius: '100px',
      paddingX: '8px',
      paddingY: '4px',
      fontSize: '16px',
      fontFamily: 'Segoe UI',
      textTransform: 'none',
      cursor: isCartCodePresent ? 'not-allowed' : 'pointer',
      '&:hover': {
        backgroundColor: isCartCodePresent ? '#000' : '#cc0000',
        color: '#fff',
        borderColor: isCartCodePresent ? '#000' : '#cc0000',
      },
    }}
  >
{autosaving || savedCartCode ? (
  <span
    className={`flex items-center gap-2 ${
      !autosaving && savedCartCode ? 'text-white' : ''
    }`}
  >
    {autosaving && (
      <span className="loader rounded-full border-2 border-white border-t-transparent w-4 h-4 animate-spin"></span>
    )}
    Autosaving Cart
  </span>
) : (
  'Save Cart for Later'
)}


  </Button>
)}


<Modal open={open} onClose={() => setOpen(false)}>
  <Box
    sx={{
      p: 4,
      backgroundColor: 'white',
      borderRadius: 2,
      width: '90%',
      maxWidth: 600,
      mx: 'auto',
      mt: '5%',
      maxHeight: '90vh',
      overflowY: 'auto',
      position: 'relative',
    }}
  >
    {/* Close button */}
    <Button
      onClick={() => setOpen(false)}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        fontSize: '13px',
        textTransform: 'none',
        color: '#EF4949',
        fontWeight: 500,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '#fce8e8',
        },
      }}
    >
      Close
    </Button>

    {/* Modal content continues */}
    <h3>Save Your Cart</h3>
    <p style={{ fontSize: '14px', marginBottom: '15px' }}>
      You can save your cart and return later to finish shopping. A link will be emailed to you, and it’ll work on any device.
    </p>

    <TextField label="First Name" fullWidth margin="normal" name="firstName" onChange={handleChange} />
    <TextField label="Last Name" fullWidth margin="normal" name="lastName" onChange={handleChange} />
    <TextField label="Email" fullWidth margin="normal" name="email" onChange={handleChange} />

    <div className="mt-4">
      <label className="text-sm mb-1 block font-medium">Arrival Date</label>
      <DatePicker
  selected={form.arrivalDate}
  onChange={(date) => setForm((prev) => ({ ...prev, arrivalDate: date }))}
  dateFormat="yyyy-MM-dd"
  minDate={new Date()}
  onKeyDown={(e) => e.preventDefault()}
  customInput={<CustomDateInput />}
/>
    </div>

    <div className="mt-4">
      <label className="text-sm mb-1 block font-medium">Arrival Time</label>
      <DatePicker
  selected={form.arrivalTime}
  onChange={(time) => setForm((prev) => ({ ...prev, arrivalTime: time }))}
  showTimeSelect
  showTimeSelectOnly
  timeIntervals={15}
  dateFormat="h:mm aa"
  onKeyDown={(e) => e.preventDefault()}
  popperPlacement="top-start"
  popperClassName="z-[9999]"
  customInput={<CustomTimeInput />}
/>
    </div>

    <FormControlLabel
      control={<Checkbox checked={form.consent} onChange={handleConsent} />}
      label={
        <span style={{ fontSize: '13px', fontWeight: 400 }}>
          I agree to receive email reminders
        </span>
      }
      sx={{ mt: 2 }}
    />

    <Button
      variant="contained"
      fullWidth
      onClick={handleSubmit}
      sx={{
        mt: 2,
        backgroundColor: 'red',
        color: 'white',
        fontFamily: 'Segoe UI',
        '&:hover': {
          backgroundColor: '#cc0000'
        }
      }}
    >
      Save Cart
    </Button>
  </Box>
</Modal>

    </>
  );
};

export default SaveCartButton;


