import React, {lazy, Suspense} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RenderLoader from './components/RenderLoader'
import Privacy from "./screens/Privacy";
import PackageCard from "./components/PackageCard";
import PackageScreen from "./screens/PackageScreen";
import PackageDetailsCard from "./components/PackageDetailsCard";
const CheckoutScreen = lazy(() => import('./screens/CheckoutScreen'))
const GuestHomeScreen = lazy(() => import('./screens/GuestHomeScreen'))
const ProductScreen = lazy(() => import('./screens/ProductScreen'))
const CartScreen = lazy(() => import('./screens/CartScreen'))
const OrderCompleted = lazy(() => import('./screens/OrderCompleted'))
const LandingPage = lazy(() => import('./screens/LandingPage'))
const Guest = lazy(() => import('./screens/Guest'))
const SavedCartGuest = lazy(() => import('./screens/SavedCartGuest'))
const OrderDriverImages = lazy(() => import('./components/OrderDriverImages'));
const PageNotFoundScreen = lazy(() => import('./screens/PageNotFoundScreen'));
const SMSCompliance = lazy(() => import('./screens/SMSCompliance'))
const OrderTracking = lazy(() => import('./screens/OrderTracking'))

const TermsAndConditions = lazy(() => import('./screens/TermsAndConditions'))


function App() {


  return (
    <div>
      <div className="flex w-full min-h-screen">

        <Routes>
          {/* <Route exact path='/'> <Navigate to="/guest" /> </Route> */}
          <Route path='/' element={<Suspense fallback={<RenderLoader/>}><LandingPage /> </Suspense>} />
          <Route path='/homeproducts' element={<Suspense fallback={<RenderLoader/>}><GuestHomeScreen /></Suspense>} />
          <Route path='/guest/:pid' element={<Suspense fallback={<RenderLoader/>}><Guest /></Suspense>} />
          <Route path='/product/:id' element={<Suspense fallback={<RenderLoader/>}><ProductScreen /></Suspense>} />
          <Route path='/cart' element={<Suspense fallback={<RenderLoader/>}><CartScreen /></Suspense>} />
          <Route path='/checkout' element={<Suspense fallback={<RenderLoader/>}><CheckoutScreen /></Suspense>} />
          <Route path='/orderCompleted' element={<Suspense fallback={<RenderLoader/>}><OrderCompleted /></Suspense>} />
          <Route path='/privacy' element={<Suspense fallback={<RenderLoader/>}><Privacy/></Suspense>} />
          <Route path='/package/:pid' element={<Suspense fallback={<RenderLoader/>}><PackageScreen/></Suspense>} />
          <Route path='/guest' element={<Suspense fallback={<RenderLoader/>}><Guest/></Suspense>} />
          <Route path='/guest/:pid/ebikes' element={<Suspense fallback={<RenderLoader/>}><Guest/></Suspense>} />
          <Route path='/order/:oid' element={<Suspense fallback={<RenderLoader/>}><OrderDriverImages /></Suspense>} />
          <Route path='/sms' element={<Suspense fallback={<RenderLoader/>}><SMSCompliance/></Suspense>} />
          <Route path='/terms' element={<Suspense fallback={<RenderLoader/>}><TermsAndConditions/></Suspense>} />
          <Route path='/order-tracking/:oid' element={<Suspense fallback={<RenderLoader/>}><OrderTracking/></Suspense>} />
          <Route path='/guest/:pid/savedcart/:cartCode' element={<Suspense fallback={<RenderLoader/>}><SavedCartGuest/></Suspense>} />
          {/* <Route path="/support" element={<SupportScreen />} /> */}
          <Route path="*" element={<PageNotFoundScreen />} />

        </Routes>

      </div>
    </div>
  );
}

export default App;
